@charset "UTF-8";
@import url(https://cdn.jsdelivr.net/font-nanum/1.0/nanumbarungothic/nanumbarungothic.css);
@import url(https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&display=swap);

html {
    overflow-x: hidden;
    overflow-y: auto;
}
html,
body {
    margin: 0px;
    padding: 0px;
}
div.center-body {
    margin: 0px;
    padding: 0px;
    font-size: 12px;
    font-family: 'Nanumbarungothic', sans-serif !important;
}
a {
    text-decoration: none;
    color: #000;
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
h1.center {
    font-size: 18px;
}
h2.center {
    margin-top: 5px;
    margin-bottom: 3px;
    color: #535353;
    font-size: 13px;
}
h2.center2 {
    margin-top: 10px;
    margin-bottom: 2px;
    font-size: 16px;
}
button {
    cursor: pointer;
}

/*공통*/
.flex {
    display: flex;
    align-items: center;
}
.flex-basic {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.center-wrap {
    background-color: #f1f5f8;
    width: 100%;
    min-width: 1024px;
    /* min-height: calc(100vh - 74px); */
    padding: 0px;
    margin: 0px;
}
.center-top {
    width: calc(100% - 30px);
    height: 60px;
    padding-top: 10px;
    padding-left: 30px;
    background-color: #3a4652;
    border-bottom: 2px solid #1359bb;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 18px;
}

.center-top p {
    font-weight: 900;
}

.center-cont {
    width: 100%;
    min-height: calc(100vh - 74px);
    border-top: 1px solid #166ec7;
    display: flex;
}

.center-left {
    width: 160px;
    height: auto;
    background-color: #4d5761;
}

.center-left-menu li {
    height: calc(((768px - 72px - 105px) / 9) - 2px);
    padding-left: 30px;
    font-size: 14px;
    font-weight: 900;
    border-bottom: 1px solid #38393b;
    border-top: 1px solid #58585a;
}
.center-left-menu li:hover {
    background: linear-gradient(to bottom, #56626e, #424c56);
}

.center-left-menu li a {
    display: block;
    color: #fff;
    line-height: 63px;
}
.center-left-menu li a i {
    width: 30px;
}
.center-left-menu li.on {
    background: linear-gradient(to bottom, #1a7fcf, #1359bb);
}
.center-left-menu li.onmore {
    background: linear-gradient(to bottom, #1a7fcf, #1359bb);
    height: calc(((768px - 72px - 105px) / 9) - 3px + 105px);
    padding: 0px;
}
.center-left-menu li.onmore > a {
    padding-left: 30px;
    display: block;
    background: linear-gradient(to bottom, #1a7fcf, #1359bb);
    border-bottom: 1px solid #1a7fcf;
    height: 63px;
}
.center-left-menu li.onmore > div.inbox {
    background-color: #68778b;
    height: 105px;
}
.center-left-menu li.onmore > div.inbox > div {
    padding: 0px;
    height: calc(105px / 3);
}
.center-left-menu li.onmore > div.inbox > div > a {
    display: block;
    line-height: calc(105px / 3);
    padding-left: 30px;
    font-size: 12px;
}
.center-left-menu li.onmore > div.inbox > div > a.ons {
    font-weight: bold;
    background-color: #1e5fb9;
}

.center-box {
    width: calc(100%);
    min-width: calc(100% - 160px);
    height: auto;
}
.center-navi {
    width: calc(100% - 20px);
    min-width: calc(100% - 200px);
    height: 50px;
    background-color: #d6dde5;
    vertical-align: middle;
    padding-left: 20px;
    display: flex;
    align-items: center;
}
.center-navi a {
    line-height: 50px;
}
.center-navi span {
    margin: auto 20px;
    font-size: 8px;
    line-height: 8px;
}
.center-navi i {
    margin-right: 10px;
}
.center-blue {
    color: #166ec7;
}
.center-blue i {
    margin-right: 5px;
}
.center-gray {
    color: #5c5c5c;
}
.center-gray i {
    margin-right: 5px;
}
.center-board {
    width: calc(100% - 40px);
    min-width: calc(100% - 160px);
    height: auto;
    min-height: calc(768px - 72px -50px);
    padding: 5px 20px;
}
.center-bnt-right {
    width: 600px;
    float: right;
    text-align: right;
}
.center-bnt-right2 {
    width: 420px;
    float: right;
    text-align: right;
}
.center-bnt-right3 {
    float: right;
    text-align: right;
}
.center-btn-right350 {
    padding-right: 30px;
    width: 380px;
    float: right;
    text-align: right;
}
.center-btn-right635 {
    padding-right: 30px;
    width: 680px;
    float: right;
    text-align: right;
}
.center-select-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px 0px 0px;
}
.center-select {
    font-size: 11px;
    font-weight: 700;
    border: 1px solid #e3e3e3;
    padding: 8px;
    margin-right: 5px;
    outline-style: none;
    cursor: pointer;
}
.center-reserved-select {
    width: 200px;
    font-size: 12px;
    border: 0px;
    padding: 15px;
    outline-style: none;
    cursor: pointer;
}
.center-select-scription {
    font-size: 13px;
    font-weight: 500;
    border: 0px;
    padding: 5px;
    outline-style: none;
    cursor: pointer;
}
.center-search input,
.center-search button {
    float: left;
}
.center-input {
    font-size: 12px;
    border: 0px;
    height: 14px;
    padding: 8px;
    outline-style: none;
}
.center-input-check {
    border: 0px;
    outline-style: none;
}
.center-button {
    font-size: 12px;
    border: 0px;
    padding: 15px;
    outline-style: none;
    cursor: pointer;
    background-color: #166ec7;
    color: #fff;
}
.center-button-yes {
    font-size: 12px;
    border: 1px solid #2a5b8b;
    border-radius: 30px;
    padding: 5px 10px;
    outline-style: none;
    cursor: pointer;
    background-color: #166ec7;
    color: #fff;
}
.center-button-no {
    font-size: 12px;
    border: 1px solid #444;
    border-radius: 30px;
    padding: 5px 10px;
    outline-style: none;
    cursor: pointer;
    background-color: #5c5c5c;
    color: #fff;
}
.center-button-cancle {
    font-size: 12px;
    border: 1px solid #bebebe;
    border-radius: 30px;
    padding: 5px 10px;
    outline-style: none;
    cursor: pointer;
    background-color: #bebebe;
    color: #000000;
}
.center-button-letter {
    font-size: 12px;
    border: 1px solid #005e75;
    border-radius: 30px;
    padding: 5px 10px;
    outline-style: none;
    cursor: pointer;
    background-color: #008299;
    color: #fff;
}
.center-button-condition {
    font-size: 12px;
    border: 1px solid #b71600;
    border-radius: 30px;
    padding: 5px 10px;
    outline-style: none;
    cursor: pointer;
    background-color: #db3a00;
    color: #fff;
}
.center-button-del {
    font-size: 12px;
    border: 1px solid #000000;
    border-radius: 30px;
    padding: 5px 10px;
    outline-style: none;
    cursor: pointer;
    background-color: #000000;
    color: #fff;
}
.center-button-result {
    font-size: 12px;
    border: 1px solid #0b7903;
    border-radius: 30px;
    padding: 5px 10px;
    outline-style: none;
    cursor: pointer;
    background-color: #2f9d27;
    color: #fff;
}
.center-button-save {
    font-size: 12px;
    font-weight: 700;
    border-radius: 30px;
    padding: 10px;
    outline-style: none;
    cursor: pointer;
    background-color: #166ec7;
    color: #fff;
    border: 1px solid #166ec7;
}
.center-button-seve i {
    margin-left: 5px;
}
.center-button-delete {
    font-size: 12px;
    border-radius: 30px;
    padding: 10px;
    outline-style: none;
    cursor: pointer;
    background-color: #c71616;
    color: #fff;
    border: 1px solid #c71616;
}

.center-button-complete {
    font-size: 15px;
    font-weight: 700;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    outline-style: none;
    cursor: pointer;
    background-color: #c71616;
    color: #fff;
    border: 1px solid #c71616;
}
.center-button-complete i {
    margin-left: 5px;
}

.center-button-close {
    font-size: 12px;
    font-weight: 700;
    border-radius: 30px;
    padding: 10px;
    outline-style: none;
    cursor: pointer;
    background-color: #fff;
    color: #333;
    border: 1px solid #eaeaea;
}
.center-button-close i {
    margin-left: 5px;
}
.center-span-ask {
    font-size: 15px;
    font-weight: 700;
    color: #2124c4;
}
.center-span-yes {
    font-size: 15px;
    font-weight: 700;
    color: #166ec7;
}
.center-span-no {
    font-size: 15px;
    font-weight: 700;
    color: #5c5c5c;
}
.center-span-cancle {
    font-size: 15px;
    font-weight: 700;
    color: #999;
}

/*로그인*/
.center-wrap-dark {
    background-color: #38393b;
    width: 100%;
    min-width: 1024px;
    height: 100vh;
    padding: 0px;
    margin: 0px;
}
.center-login-bg {
    background-image: url(../img/sub-visual-3.jpg);
    background-size: cover;
    background-position: center top;
    width: 100%;
    height: calc(100vh - 272px);
    padding: 100px 0px;
    opacity: 0.5;
}
.center-login {
    background-color: #3a4652;
    width: 300px;
    height: 400px;
    border-radius: 30px;
    margin: 0 auto;
    box-shadow: 0px 5px 3px 3px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: calc(50% - 200px);
    left: calc(50% - 150px);
}
.center-login-title {
    padding: 30px 0px 0px 30px;
    height: 70px;
    line-height: 60px;
    border-bottom: 2px solid #166ec7;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
}
.center-white {
    color: #fff;
}
.center-login-box {
    padding: 30px;
}
.center-input-login:first-child {
    margin-top: 20px;
}
.center-input-login {
    margin-top: 2px;
    font-size: 12px;
    border: 0px;
    padding: 15px;
    outline-style: none;
    width: calc(100% - 30px);
}
.center-button-login {
    margin-top: 10px;
    border: 1px solid #1359bb;
    outline-style: none;
    background: linear-gradient(to bottom, #1a7fcf, #1359bb);
    width: calc(100%);
    padding: 20px;
    border-radius: 50px 0px 50px 50px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}
.center-button-login:hover {
    background: linear-gradient(to bottom, #fff, #eee);
    color: #1359bb;
}

/*list 예약 상담*/
.center-list {
    clear: both;
    min-height: 450px;
    padding-top: 5px;
}
.center-list-table {
    width: 100%;
}
.center-list-table thead tr td:first-child {
    border-radius: 25px 0px 0px 0px;
}
.center-list-table thead tr td:last-child {
    border-radius: 0px 25px 0px 0px;
}
.center-list-table thead tr td {
    text-align: center;
    background-color: #5c5c5c;
    color: #fff;
    padding: 10px 0px;
    font-size: 15px;
    font-weight: 800;
    border: 0px;
}
.center-list-table tbody tr td {
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
    height: 28px;
    padding: 5px 0px;
    font-weight: 500;
    font-size: 15px;
}
.center-list-table tbody tr td select {
    padding: 5px;
}

.center-information-table {
    width: 350px;
}
.center-information-table thead tr td:first-child {
    border-radius: 25px 0px 0px 0px;
}
.center-information-table thead tr td:last-child {
    border-radius: 0px 25px 0px 0px;
}
.center-information-table thead tr td {
    text-align: center;
    background-color: #5c5c5c;
    color: #fff;
    padding: 10px 0px;
    font-size: 18px;
    font-weight: 900;
    border: 0px;
}
.center-information-table tbody tr td {
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
    height: 28px;
    padding: 5px 0px;
    font-weight: 700;
    font-size: 15px;
}
.center-information-table tbody tr td select {
    padding: 5px;
}

#center-popup-wrap {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    min-width: 1024px;
    height: 100%;
    min-height: 768px;
    background-color: rgba(0, 0, 0, 0.5);
}

#center-question-detail-wrap {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    min-width: 1024px;
    height: 100%;
    min-height: 768px;
    background-color: rgba(0, 0, 0, 0.5);
}

#center-check-wrap {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    min-width: 1024px;
    height: 100%;
    min-height: 768px;
    background-color: rgba(0, 0, 0, 0.5);
}
#center-popup-list {
    display: block;
}
#list-tab {
    display: none;
}
#tabstory2,
#tabstory3,
#tabstory4,
#tabstory5 {
    display: none;
}
.center-popup {
    animation-name: popup;
    animation-duration: 1s;
    position: absolute;
    top: calc(50% - 350px);
    left: calc(50% - 430px);
    overflow-y: auto;
    width: calc(900px - 40px);
    height: 700px;
    max-height: calc(900px - 40px);
    min-height: 600px;
    margin: 0px auto;
    padding: 5px;
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #1a7fcf;
}
.center-recording-popup {
    animation-name: popup;
    animation-duration: 1s;
    position: absolute;
    top: calc(50% - 350px);
    left: calc(50% - 430px);
    overflow-y: auto;
    width: calc(900px - 40px);
    height: 655px;
    max-height: calc(900px - 40px);
    min-height: 600px;
    margin: 0px auto;
    padding: 5px;
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #1a7fcf;
}
.center-question-popup {
    animation-name: popup;
    animation-duration: 1s;
    position: absolute;
    top: calc(50% - 350px);
    left: calc(50% - 430px);
    overflow-y: auto;
    width: calc(900px - 40px);
    height: 655px;
    max-height: calc(900px - 40px);
    min-height: 600px;
    margin: 0px auto;
    padding: 5px;
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #1a7fcf;
}
.center-popup-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.center-popup-bnt {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #166ec7;
}
.center-popup-bnt a.on {
    background-color: #166ec7;
    color: #fff;
}
.center-popup-bnt a {
    width: 50%;
    padding: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 800;
    color: #166ec7;
    border-bottom: 1px solid #5dadfc;
    background-color: #eee;
}
.center-popup-bnt a:first-child {
    border-radius: 30px 0px 0px 0px;
}
.center-popup-bnt a:last-child {
    border-radius: 0px 30px 0px 0px;
}
.center-popup-bnt a i {
    margin-right: 10px;
}

.center-popup-bnt > div.popup-inner {
    width: 100%;
    border-radius: 30px 30px 0px 0px;
    background-color: #166ec7;
    color: #fff;
    padding: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 800;
}

div.popup-inner i {
    padding: 0px 5px;
}

.center-popup-list-box {
    /* max-height: 525px; */
    padding: 0px 5px 5px 5px;
    margin-top: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    /* max-width: calc(900px - 40px); */
}

.center-popup-list-box span {
    font-size: 15px;
    padding-left: 15px;
}
.center-popup-list-box::-webkit-scrollbar {
    width: 5px;
    background-color: #eee;
}
.center-popup-list-box::-webkit-scrollbar-thumb {
    width: 10px;
    background-color: #166ec7;
}

.center-popup-checking-box {
    max-height: 640px;
    padding: 0px 5px 5px 5px;
    margin-top: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: calc(900px - 40px);
}

.center-popup-checking-box span {
    font-size: 15px;
    padding-left: 15px;
}
.center-popup-checking-box::-webkit-scrollbar {
    width: 5px;
    background-color: #eee;
}
.center-popup-checking-box::-webkit-scrollbar-thumb {
    width: 10px;
    background-color: #166ec7;
}

.center-popup-reporting-box {
    max-height: 480px;
    padding: 0px 5px 5px 5px;
    margin-top: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: calc(900px - 40px);
}
.center-popup-reporting-box::-webkit-scrollbar {
    width: 5px;
    background-color: #eee;
}
.center-popup-reporting-box::-webkit-scrollbar-thumb {
    width: 10px;
    background-color: #166ec7;
}

.center-popup-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0px;
    border-bottom: 1px solid #eaeaea;
}
.center-popup-list-tab {
    display: flex;
    min-height: 450px;
    overflow-y: hidden;
    overflow-x: hidden;
    border: 1px solid #166ec7;
}
.center-popup-question-tab {
    display: flex;
    height: 580px;
    overflow-y: hidden;
    overflow-x: hidden;
    border-left: 1px solid #166ec7;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #166ec7;
}
.center-popup-control-list-tab {
    display: flex;
    height: 525px;
    overflow-y: hidden;
    overflow-x: hidden;
    border-left: 1px solid #166ec7;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #166ec7;
}
.center-popup-complete-list-tab {
    display: flex;
    height: 489px;
    overflow-y: hidden;
    overflow-x: hidden;
    border-left: 1px solid #166ec7;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #166ec7;
}
.tab-menu {
    width: 117px;
    min-height: 450px;
    float: left;
    border-right: 1px solid #166ec7;
}

.tab-menu ul li {
    background-color: #fff;
    color: #166ec7;
    padding: 15px 0px 15px 0px;
    border-bottom: 1px solid #166ec7;
    text-align: center;
}
.tab-menu ul li button {
    background-color: #fff;
    border: 0px;
    outline-style: none;
}
.tab-menu ul li.tabon {
    background-color: #166ec7;
    padding: 15px 0px 15px 10px;
    border-bottom: 1px solid #166ec7;
    color: #fff;
    font-weight: 900;
    font-size: 15px;
}

.tab-menu ul li.taboff {
    background-color: #fff;
    color: #166ec7;
    padding: 15px 0px 15px 10px;
    border-bottom: 1px solid #166ec7;
    font-weight: 500;
    font-size: 15px;
}

.tab-story {
    width: 100%;
    /* float: left; */
    line-height: 18px;
    padding: 0px 10px 10px 10px;
    height: auto;
    overflow-y: auto;
}

.tab-story p {
    font-size: 15px;
}

.tab-story-number > ul > li {
    font-size: 12px;
}
.tab-story-number > ul > li.selected {
    color: #ff0000;
    font-weight: 700;
}
.tab-story-number > h3 {
    font-size: 15px;
}
.tab-story-number > h4 {
    font-size: 14px;
}
.center-table-bio-box {
    padding: 0px 0px !important;
    font-size: 13px;
}
.tab-story::-webkit-scrollbar {
    width: 5px;
    background-color: #eee;
}
.tab-story::-webkit-scrollbar-thumb {
    width: 10px;
    background-color: #166ec7;
}

.tab-complete-story {
    max-width: calc(900px - 180px);
    /* float: left; */
    line-height: 18px;
    padding: 0px 10px 10px 10px;
    height: 480px;
    overflow-y: auto;
}

.tab-complete-story p {
    font-size: 15px;
}
.center-table-bio-box {
    padding: 0px 0px !important;
    font-size: 13px;
}
.tab-complete-story::-webkit-scrollbar {
    width: 5px;
    background-color: #eee;
}
.tab-complete-story::-webkit-scrollbar-thumb {
    width: 10px;
    background-color: #166ec7;
}

.center-center {
    text-align: center;
    margin: 30px auto;
    font-size: 18px;
    font-weight: bold;
    color: #166ec7;
}
.check-on {
    color: red;
}
.clear {
    clear: both;
}
@-webkit-keyframes popup {
    0% {
        top: 200px;
        opacity: 0.2;
    }
    100% {
        top: calc(50% - 350px);
        opacity: 1;
    }
}

/*상태측정 팝업*/
#center-check-wrap {
    display: none;
}
#check-dot-group {
    position: relative;
    display: block;
}
.check-dot {
    position: absolute;
    cursor: pointer;
    /* background-color: #e9260c; */
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 10px;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    border: 3px solid #e3e3e3;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.3);
}
.center-popup-check {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px;
}

.center-popup-check select {
    width: 200px;
    border: 1px solid #eaeaea;
    background-color: #fff;
    outline-style: none;
    height: 25px;
    padding: 0px 1px;
    font-size: 12px;
    font-weight: 700;
}

.center-popup-check select.active {
    border: 2px solid #e09d84;
    background-color: #e8f1ff;
    color: #bd625d;
}

.center-vas-check {
    display: flex;
    height: 290px;
    overflow-y: hidden;
    overflow-x: hidden;
    /* border-bottom: 1px solid #166ec7; */
}

.center-check-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    border-bottom: 1px solid #eaeaea;
}
.center-popup-tit {
    font-size: 14px;
    margin: 10px 0px 3px 0px;
    font-weight: bold;
}
.center-vas-check-bg {
    /* height: calc(577px * 80 / 100);
    width: calc(250px * 80 / 100);
    border: 1px solid #000;
    float: left;
    background-image: url(../img/body_up_down.png);
    background-size: calc(250px * 80 / 100) calc(577px * 80 / 100); */
    width: calc(608px * 80 / 100);
    height: calc(344px * 80 / 100);
    background-image: url(../img/3-1.png);
    background-size: calc(608px * 80 / 100) calc(344px * 80 / 100);
}

.center-vas-check-right {
    /* max-width: calc(900px - 280px); */
    width: 280px;
    /* float: left; */
    line-height: 18px;
    padding: 0px 10px 10px 10px;
    overflow-y: auto;
}
.check-dot-select {
    background-color: #c71616;
}
/* left part */
.check-dot-lflank {
    /* 왼옆구리 */
    top: 75px;
    left: 35px;
}
.check-dot-lwrist {
    /* 왼손목 */
    top: 115px;
    left: 67px;
}
.check-dot-lthigh {
    /* 왼허벅지 */
    top: 152px;
    left: 32px;
}
.check-dot-lankle {
    /* 왼발목 */
    top: 235px;
    left: 43px;
}
.check-dot-lfoot {
    /* 왼발 */
    top: 245px;
    left: 19px;
}
/* Right part */
.check-dot-rflank {
    /* 오른옆구리 */
    top: 75px;
    left: 285px;
}
.check-dot-rwrist {
    /* 오른손목 */
    top: 115px;
    left: 255px;
}
.check-dot-rthigh {
    /*오른허벅지*/
    top: 152px;
    left: 289px;
}
.check-dot-rankle {
    /* 오른발목 */
    top: 238px;
    left: 278px;
}
.check-dot-rfoot {
    /* 오른발 */
    top: 247px;
    left: 303px;
}
/* front part */
.check-dot-neck {
    /* 목 */
    top: 30px;
    left: 163px;
}
.check-dot-lshoul {
    /* 왼어깨 */
    top: 45px;
    left: 193px;
}
.check-dot-rshoul {
    /* 오른어깨 */
    top: 45px;
    left: 130px;
}
.check-dot-chest {
    /* 가슴 */
    top: 60px;
    left: 163px;
}
.check-dot-stomach {
    /* 복부 */
    top: 100px;
    left: 163px;
}
.check-dot-lknee {
    /* 왼무릎 */
    top: 182px;
    left: 174px;
}
.check-dot-rknee {
    /* 오른무릎 */
    top: 182px;
    left: 149px;
}

/* back part */
.check-dot-back {
    /* 등 */
    top: 55px;
    left: 402px;
}
.check-dot-waist {
    /* 허리 */
    top: 105px;
    left: 402px;
}
.check-dot-hips {
    /* 엉덩이 */
    top: 126px;
    left: 382px;
}
.check-dot-lclaf {
    /* 왼종아리 */
    top: 200px;
    left: 382px;
}
.check-dot-rclaf {
    /* 오른종아리 */
    top: 200px;
    left: 417px;
}

.body-bg {
    background-image: url(../img/man.jpg);
    background-size: cover;
}

.center-check-table {
    border: 0px;
    width: 95%;
}
.center-check-table thead td {
    padding: 5px 5px 2px 5px;
    font-size: 13px;
    font-weight: 700;
}
.center-check-table tbody tr td {
    height: 30px;
    text-align: center;
    border-bottom: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    border-spacing: 10px 0px;
    font-size: 12px;
    font-weight: 700;
}
.center-check-table tbody tr:first-child td {
    border-top: 1px solid #eaeaea;
}
.center-check-table tbody tr:first-child td:first-child {
    border-radius: 3px 0px 0px 0px;
}
.center-check-table tbody tr:first-child td:last-child {
    border-radius: 0px 3px 0px 0px;
}
.center-check-table tbody tr:last-child td:first-child {
    border-radius: 0px 0px 0px 3px;
}
.center-check-table tbody tr:last-child td:last-child {
    border-radius: 0px 0px 3px 0px;
}
.center-check-table tbody tr td:nth-child(1) {
    width: 50px;
    text-align: center;
    border-left: 1px solid #eaeaea;
    background-color: #166ec7;
    color: #fff;
}
.center-check-table tbody tr td:nth-child(2) {
    width: 100px;
    text-align: left;
}
.center-check-table tbody tr td:nth-child(2) input {
    width: 90px;
    padding-left: 5px;
}
.center-check-table tbody tr td input {
    width: 89%;
    height: 35px;
    outline-style: none;
    border: 0px;
}

.center-check-resulttable {
    border: 0px;
    width: 570px;
}
.center-check-resulttable tbody tr td {
    height: 30px;
    text-align: center;
    border-bottom: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    border-spacing: 10px 0px;
    font-size: 12px;
    font-weight: 700;
}
.center-check-resulttable tbody tr:first-child td {
    border-top: 1px solid #eaeaea;
}
.center-check-resulttable tbody tr:first-child td:first-child {
    border-radius: 5px 0px 0px 0px;
}
.center-check-resulttable tbody tr:first-child td:last-child {
    border-radius: 0px 5px 0px 0px;
}
.center-check-resulttable tbody tr:last-child td:first-child {
    border-radius: 0px 0px 0px 5px;
}
.center-check-resulttable tbody tr:last-child td:last-child {
    border-radius: 0px 0px 5px 0px;
}
.center-check-resulttable tbody tr td:nth-child(1) {
    width: 35px;
    text-align: center;
    border-left: 1px solid #eaeaea;
    background-color: #166ec7;
    color: #fff;
}
.center-check-resulttable tbody tr td:nth-child(2) {
    width: 100px;
    text-align: left;
}
.center-check-resulttable tbody tr td:nth-child(2) input {
    width: 98px;
    padding-left: 10px;
}
.center-check-resulttable tbody tr td input {
    width: 30px;
    height: 30px;
    outline-style: none;
    border: 0px;
}
.center-bg-gray {
    background-color: #eaeaea;
    padding: 0px !important;
}
.center-bg-shadow-gray {
    background-color: #f3f3f3;
    box-shadow: 2px 2px gray;
    padding: 0px !important;
}
.center-input-text {
    border: 1px solid #eaeaea;
    width: calc(900px - 80px);
    height: 40px;
    padding-left: 10px;
    outline-style: none;
}

/*측정하기 팝업*/
#list-from {
    display: none;
}
.center-birth {
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 30px;
    background-color: #166ec7;
    color: #fff;
}
.center-input-gray {
    background-color: #eaeaea;
    font-size: 12px;
    border: 0px;
    padding: 15px;
    outline-style: none;
}
.center-input-gray-script {
    margin: 0px 3px;
    background-color: #dddddd;
    font-size: 13px;
    border: 0px;
    padding: 5px;
    outline-style: none;
}

.center-input-bio {
    background-color: none;
    font-size: 12px;
    border: 0px;
    padding: 0px;
    outline-style: none;
    width: 30px;
}
.center-bg-blue {
    background-color: #3a4652;
    color: #fff;
    text-align: center;
    padding: 0px !important;
    width: 60px;
    height: 44px;
    font-size: 13px;
    line-height: 44px;
}
.center-bg-blue-big {
    background-color: #3a4652;
    color: #fff;
    text-align: center;
    padding: 0px !important;
    font-size: 14px;
    width: 150px;
    height: 44px;
    line-height: 44px;
}
.center-label {
    padding: 0px;
    width: 60px;
    font-size: 13px;
    text-align: center;
}
.center-half {
    width: calc(50% - 20px);
}
.center-half > div > input {
    width: 85%;
    text-align: right;
    font-size: 15px;
    font-weight: 900;
}
.center-half > div > label {
    text-align: right;
    padding-right: 10px;
    font-size: 15px;
    font-weight: 900;
}
.center-trisection {
    width: calc(33% - 20px);
}
.center-trisection > div > input {
    width: 90%;
}
.center-w50 {
    width: 50px;
}
.center-w80 {
    width: 80px;
}
.center-w100 {
    width: 100px;
}
.center-w120 {
    width: 120px;
}
.center-w150 {
    width: 150px;
}
.center-w180 {
    width: 180px;
}
.center-w200 {
    width: 200px;
}
.center-w300 {
    width: 300px;
}
.center-w400 {
    width: 400px;
}
.center-w450 {
    width: 450px;
}
.center-w500 {
    width: 500px;
}
.center-w550 {
    width: 550px;
}
.center-w600 {
    width: 600px;
}
.center-w650 {
    width: 650px;
}
.center-w700 {
    width: 700px;
}
.center-mauto {
    text-align: center;
    margin: 15px auto;
}
.center-special-physical-btn {
    text-align: right;
    margin: 15px auto;
}
.center-controller {
    text-align: center;
    margin: 5px auto;
}
.center-label-up {
    font-size: 14px;
    border-radius: 30px;
    vertical-align: middle;
    padding: 10px 20px;
    margin-top: 20px;
    outline-style: none;
    cursor: pointer;
    background-color: #3a4652;
    color: #fff;
    border: 1px solid #eaeaea;
}

.center-button-up {
    font-size: 14px;
    border-radius: 30px;
    padding: 10px 20px;
    outline-style: none;
    cursor: pointer;
    background-color: #3a4652;
    color: #fff;
    border: 1px solid #eaeaea;
}
.center-button-print {
    font-size: 14px;
    border-radius: 30px;
    padding: 10px 20px;
    outline-style: none;
    cursor: pointer;
    background-color: #166ec7;
    color: #fff;
    border: 1px solid #eaeaea;
}
.center-button-adminplus {
    font-size: 14px;
    border-radius: 30px;
    padding: 10px 20px;
    outline-style: none;
    cursor: pointer;
    background-color: #b71600;
    color: #fff;
    border: 1px solid #eaeaea;
}
.center-button-ssave {
    font-size: 14px;
    border-radius: 30px;
    padding: 10px 20px;
    outline-style: none;
    cursor: pointer;
    background-color: #fff;
    color: #444;
    border: 1px solid #eaeaea;
}
.center-button-ssave:hover {
    background-color: #f56f03;
    color: #fff;
}
.center-button-esave {
    font-size: 14px;
    border-radius: 30px;
    padding: 10px 20px;
    outline-style: none;
    cursor: pointer;
    background-color: #fff;
    color: #444;
    border: 1px solid #eaeaea;
}
.center-button-esave:hover {
    background-color: #0e733a;
    color: #fff;
}
.center-button-resultsend {
    font-size: 14px;
    border-radius: 30px;
    padding: 10px 20px;
    outline-style: none;
    cursor: pointer;
    background-color: #fff;
    color: #444;
    border: 1px solid #eaeaea;
}
.center-bgcolor-mint {
    background-color: #008299;
    color: #fff;
}
.center-bgcolor-gray {
    background-color: #d4d3d3;
}
.center-bgcolor-lgray {
    font-size: 11px;
    background-color: #eaeaea;
}
.center-bgcolor-lgray input {
    font-size: 11px;
    background-color: #eaeaea;
}
.center-bgcolor-blue {
    background-color: #166ec7;
    color: #fff;
}
.center-bgcolor-navy {
    background-color: #3a4652;
    color: #fff;
}
.center-table-bio {
    /* width: 580px; */
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
}
.center-table-bio tr td {
    padding: 5px 2px;
    font-size: 13px;
    border-bottom: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
}
.center-table-bio tr td.in {
    text-align: center;
}
.center-table-bio tr td:first-child {
    border-left: 1px solid #fff;
}
.center-table-bio thead tr:first-child td {
    border-top: 1px solid #fff;
}
.center-table-bio thead tr td {
    font-weight: 700;
}

.center-table-bio2 {
    width: 100%;
}
.center-table-bio2 tr td {
    padding: 10px 2px;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
}
.center-table-bio2 tr td.in {
    font-size: 12px;
    text-align: center;
}
.center-table-bio2 tr td:first-child {
    border-left: 1px solid #fff;
}
.center-table-bio2 thead tr:first-child td {
    border-top: 1px solid #fff;
}
.center-table-bio2 thead tr td {
    font-weight: 700;
}

/*운동처방*/
#center-popup-list2 {
    display: none;
}
#center-popup-list3 {
    display: none;
}
#center-popup-list4 {
    display: none;
}
#body-part {
    display: none;
}
#ball {
    display: none;
}
#step1 {
    display: block;
}
#step2 {
    display: none;
}
#step3 {
    display: none;
}
#part1 {
    display: block;
}
#part2 {
    display: none;
}
#part3 {
    display: none;
}

.flex-top {
    display: flex;
}
.flex-top > span {
    position: relative;
    top: 10px;
    width: 100%;
    height: 50px;
    font-size: 15px;
    font-weight: 900;
    color: #5b509b;
    text-align: center;
}
.tabonleft {
    position: fixed;
    width: 140px;
    padding-left: 10px;
    line-height: 18px;
}
.tabonleft > h2 a {
    color: #166ec7;
}
.tabonleft > ul {
    padding: 0px 10px;
}
.tabonleft > ul > li > ul {
    padding: 0px 10px;
}
.tabonleft > ul > li {
    font-size: 12px;
}

.tabonright {
    position: relative;
    left: 150px;
    background-color: #fff;
    margin-top: 10px;
    padding: 0px 5px 5px 5px;
    width: 685px;
    min-height: 550px;
    box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.3);
}
.tabonright div {
    /* padding-top: 30px; */
}
.tabonright div > div {
    padding: 0px 5px;
}
.tabonright div > div > p {
    font-size: 16px;
    color: #000;
    font-weight: bold;
}
.tabonright div > div > div > table.center-table-bio tbody tr td {
    padding: 3px 0px;
}
.tabonright-chart {
    position: relative;
    left: 150px;
    background-color: #fff;
    margin-top: 10px;
    padding: 0px 5px 5px 5px;
    width: 680px;
    min-height: 550px;
    box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.3);
}

.tabonright-chart div > div {
    width: 50%;
    padding: 0px 3px;
}
.tabonright-chart div > div.full-chart {
    width: 100%;
    padding: 0px 3px;
}
.tabonright-chart div > div > p {
    font-size: 16px;
    color: #000;
    font-weight: bold;
}
.tabonright-chart div > div > div > table.center-table-bio tbody tr td {
    padding: 3px 0px;
}
.tabonright-print {
    position: relative;
    width: 80px;
    height: 30px;
    left: 600px;
    top: 10px;
    font-size: 14px;
    border-radius: 25px;
    padding: 5px 20px;
    outline-style: none;
    cursor: pointer;
    background-color: #166ec7;
    color: #fff;
    border: 1px solid #eaeaea;
}
.center-graph tr td {
    padding: 0px 10px;
    vertical-align: bottom;
}
.center-graph tr:first-child td {
    border-bottom: 1px solid #333;
}
.center-graph tr:nth-child(2) td {
    padding-top: 10px;
}
.center-graph-40 {
    height: 40px;
    width: 30px;
    background-color: #eaeaea;
}
.center-graph-60 {
    height: 60px;
    width: 30px;
    background-color: #eaeaea;
}
.center-graph-80 {
    height: 80px;
    width: 30px;
    background-color: #eaeaea;
}
.center-textarea-s {
    width: 95%;
    border: 1px solid #eaeaea;
    background-color: #fff;
    outline-style: none;
    height: 150px;
    margin-right: 20px;
    padding: 0px 1px;
    padding-left: 10px;
}

.center-co {
    width: 100%;
    border-spacing: 0px;
    margin-top: 15px;
}

.center-co tr td {
    padding: 10px;
    font-size: 12px;
    border-bottom: 1px solid #b3b3b3;
}
.center-button-gra {
    font-size: 12px;
    border: 0px;
    padding: 5px 5px 5px 5px;
    height: 50px;
    width: calc((610px / 10) - 1px);
    margin-top: 1px;
    margin-right: 1px;
    text-align: center;
    outline-style: none;
    cursor: pointer;
    background: linear-gradient(to bottom, #243b55, #141e30);
    color: #fff;
}
.center-button-gra:hover {
    background: linear-gradient(to bottom, #005c97, #363795);
}
.gra-on {
    background: linear-gradient(to bottom, #005c97, #363795) !important;
}
.center-button-gra i {
    font-size: 20px;
}
.border-ba {
    margin-top: 1px;
}

.center-part-btn-group {
    margin-left: 20px;
}

/*재활*/
.center-print {
    height: calc(297mm - 30px);
    padding: 0px 0px 0px 0px;
}
.center-print-title {
    font-size: 30px;
    text-align: center;
    font-family: 'NanumMyeongjo';
    font-weight: bold;
}
.center-print-subject {
    font-size: 18px;
    text-align: center;
    font-family: 'NanumMyeongjo';
    margin-top: 200px;
}
.center-print-underline {
    text-decoration: underline;
}
.center-print-namewrap {
    margin-top: 500px;
    height: 50%;
}
.center-print-name {
    text-align: left;
    display: flex;
    font-size: 16px;
}
.center-print-name div {
    padding-top: 30px;
}
.center-print-name div:first-child {
    margin-right: 15px;
    width: 150px;
    font-weight: bold;
}
.center-print-name div:last-child {
    width: 200px;
    border-bottom: 1px solid #444;
    padding-left: 20px;
}
.center-print-logo {
    text-align: right;
    bottom: 10px;
    position: relative;
    vertical-align: bottom;
    height: 5%;
}
.center-print-logo span {
    padding: 5px 10px;
    border-radius: 30px;
}

/*통계*/
#list-tab {
    display: none;
}
#list-tab-in2,
#list-tab-in3,
#list-tab-in5,
#list-tab-in6 {
    display: none;
}
.center-three a {
    width: 33%;
}
.center-select-prescription {
    padding-top: 10px;
    padding-bottom: 10px;
}
.center-tabbt-s {
    width: 100%;
    text-align: center;
    margin-top: 0px;
}
.center-tabbt-s a {
    min-width: 100px;
    padding: 15px 20px;
    display: block;
    font-size: 14px;
    font-weight: 700;
    flex: 1;
    background-color: #fff;
    border-top: 1px solid #166ec7;
    border-bottom: 1px solid #166ec7;
    border-right: 1px solid #166ec7;
}
.center-tabbt-s a:first-child {
    border-left: 1px solid #166ec7;
}
.center-tabbt-s a.on {
    background-color: #166ec7;
    color: #fff;
}
.list-tab-in {
    padding: 20px 0px;
}

/*설정*/
#unlock {
    display: none;
}
#bt-sethome {
    text-align: right;
}
.center-loginset-text {
    margin-right: 10px;
    font-weight: bold;
}
.center-loginset-text h2 i {
    margin-right: 10px;
    color: #166ec7;
    width: 10px;
}
.center-loginset-bt-bg {
    width: 40px;
    border-radius: 30px;
    background-color: #166ec7;
    padding: 3px;
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.5) inset;
    cursor: pointer;
}
.center-loginset-bt {
    background-color: #fff;
    font-size: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    outline-style: none;
    border: 0px;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.5);
}
.center-passwordset {
    width: 350px;
}

/*계정관리*/
.center-pagenumber {
    margin-top: 50px;
}
.center-pagenumbering {
    padding: 0px;
}
.center-pagenumbering li {
    float: left;
    text-align: center;
    width: 30px;
    border-top: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    background-color: #fff;
    padding: 0px 0px;
}
.center-pagenumbering li:first-child {
    border-left: 1px solid #eaeaea;
}

.center-pagenumbering li button {
    display: block;
    width: 30px;
    height: 30px;
    margin: 0px auto;
    color: #999;
    background-color: #fff;
    font-size: 10px;
    border: 0px solid #eaeaea;
}
.center-pagenumbering button.page-on {
    background-color: #166ec7;
    font-weight: 700;
    font-size: 12px;
    color: #fff;
}
.center-pagenumber > div > label {
    margin-left: 10px;
}

/*운동처방관리*/
.center-label-sports {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
    width: 80px;
}
.center-select-sports {
    margin-left: -10px;
    width: 300px;
    border: 1px solid #eaeaea;
    background-color: #fff;
    outline-style: none;
    height: 40px;
    padding: 0px 1px;
}
.prescription-box {
    padding-top: 5px !important;
}

.prescription-box > .tit {
    font-size: 13px;
    font-weight: 700;
}

.prescription-basis .tit {
    font-size: 14px;
    font-weight: 700;
}

.prescription-basis .sub-tit {
    font-size: 12px;
    color: #535353;
}

.prescription-exercises-tit {
    font: 15px;
    font-weight: 700;
    padding: 10px 5px 20px 5px;
}

.center-pd10 {
    padding: 10px;
}
.center-pd20 {
    padding: 20px;
}
.center-tpd5 {
    padding-top: 5px;
}
.center-tpde5 {
    padding-top: 5px;
    padding-bottom: 5px;
}
.center-tpd10 {
    padding-top: 10px;
}
.center-tpd20 {
    padding-top: 20px;
}
.center-input-sports {
    width: 700px;
    border: 1px solid #eaeaea;
    background-color: #fff;
    outline-style: none;
    height: 40px;
    padding: 0px 1px;
    padding-left: 10px;
}
.center-textarea {
    width: 700px;
    border: 1px solid #eaeaea;
    background-color: #fff;
    outline-style: none;
    height: 100px;
    padding: 0px 1px;
    padding-left: 10px;
}
.center-sports-photo {
    width: 280px;
    height: 150px;
    background-color: #eaeaea;
    text-align: right;
    margin-left: -10px;
}
.center-sports-photo > div > img {
    width: 280px;
    height: 130px;
}
.center-sports-photo > label {
    padding: 2px 4px;
    font-size: 13px;
    background-color: #166ec7;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}
.center-sports-video {
    width: 280px;
    height: 150px;
    background-color: #eaeaea;
    text-align: right;
}
.center-sports-video > label {
    padding: 2px 4px;
    font-size: 13px;
    background-color: #166ec7;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}

/*설문관리*/
.center-label-questionnaire {
    margin-right: 10px;
    font-size: 13px;
    font-weight: 700;
    width: 80px;
}
.center-label-questionnaire > span {
    font-size: 10px;
    font-weight: 700;
    color: #f00;
    padding: 0px !important;
}

.center-tbpd10 {
    padding: 10px 0px;
}
.center-tbpd20 {
    padding: 20px 0px;
}
.center-bpd10 {
    padding-bottom: 20px;
}
.center-bpd20 {
    padding-bottom: 20px;
}
.center-input-sports {
    width: 700px;
    border: 1px solid #eaeaea;
    background-color: #fff;
    outline-style: none;
    height: 30px;
    padding: 0px 1px;
    padding-left: 10px;
}
.center-textarea {
    width: 700px;
    border: 1px solid #eaeaea;
    background-color: #fff;
    outline-style: none;
    height: 100px;
    padding: 0px 1px;
    padding-left: 10px;
}
.center-sports-photo {
    width: 280px;
    height: 150px;
    background-color: #eaeaea;
    text-align: right;
    margin-left: -10px;
}
.center-sports-video {
    width: 280px;
    height: 150px;
    background-color: #eaeaea;
    text-align: right;
}
.center-border-bottom {
    border-bottom: 1px solid #6d6d6d;
}
.center-sports-report {
    width: 653px;
    margin: 5px;
    padding: 10px 10px !important;
}
.center-sports-report label {
    font-size: 13px;
    font-weight: 700;
}
.center-input-sports-in {
    width: 530px;
    border: 1px solid #eaeaea;
    background-color: #fff;
    outline-style: none;
    height: 30px;
    padding: 0px 1px;
    padding-left: 10px;
}
.center-plus-option {
    width: 673px;
    margin: 0px 5px;
    padding: 10px;
    text-align: center;
    background-color: #3a4652;
    color: #fff;
    border: 1px solid #333;
    outline-style: none;
}
.center-plus-option:hover {
    background-color: #333;
}
.center-plus-cate {
    width: 100%;
    padding: 10px;
    text-align: center;
    background-color: #166ec7;
    color: #fff;
    border: 1px solid #04449e;
    outline-style: none;
}
.center-plus-cate:hover {
    background-color: #04449e;
}
.center-remove-cate {
    width: 50%;
    padding: 10px;
    text-align: center;
    background-color: #c71616;
    color: #fff;
    border: 1px solid #4b0000;
    outline-style: none;
}
.center-remove-cate:hover {
    background-color: #4b0000;
}

.chart-area {
    width: 100%;
}

.chart-container {
    float: left;
}

.width30Rate {
    width: 30%;
}

.width33Rate {
    width: 33.33%;
}

.chart-title {
    text-align: center;
}

/*추가 12. 14*/
.center-check-table-basic {
    padding-bottom: 20px;
    display: block;
}
.center-check-input-name {
    text-align: right;
}
.center-check-input-name input {
    border: 0px;
    border-bottom: 1px solid #444;
    width: 80px;
    outline-style: none;
}
.center-check-input-name input.center-day {
    width: 40px;
}
.center-check-table-basic tr td,
.center-check-table-basic tr th {
    border-bottom: 0.5px solid #eaeaea;
    border-right: 0.5px solid #eaeaea;
    text-align: left;
    font-size: 13px;
    padding: 5px;
}
.center-check-table-basic tr td:first-child,
.center-check-table-basic tr th {
    border-left: 0.5px solid #eaeaea;
}
.center-check-table-basic thead tr th {
    text-align: left;
    background-color: #eaeaea;
    padding: 10px;
}
.center-check-table-basic thead tr td {
    border-top: 1px solid #eaeaea;
}
.center-check-table-basic tbody tr th {
    text-align: left;
    font-size: 13px;
    width: 360px;
}
.center-check-table-basic tbody tr td {
    text-align: center;
    font-size: 12px;
    width: 45px;
}
.center-bottom-black th,
.center-bottom-black td {
    border-bottom: 2px solid #444 !important;
}

.center-check-table-full {
    padding-bottom: 20px;
    display: block;
}
.center-check-table-full tr td,
.center-check-table-full tr th {
    border-bottom: 0.5px solid #eaeaea;
    border-right: 0.5px solid #eaeaea;
    padding: 5px;
}
.center-check-table-full tr td:first-child,
.center-check-table-full tr th {
    border-left: 0.5px solid #eaeaea;
}
.center-check-table-full thead tr th {
    text-align: left;
    font-size: 13px;
    background-color: #eaeaea;
    padding: 10px;
}
.center-check-table-full tbody tr th {
    text-align: left;
    font-size: 12px;
    width: 610px;
}

/*공통추가*/
.center-td-blue {
    background-color: #166ec7 !important;
    color: #fff;
}
.center-text-center {
    text-align: center !important;
}
.center-w30 {
    width: 30px;
}
.center-td-widthfull tr td {
    width: 500px;
}

.center-modal-show {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #e3e3e3;
    opacity: 0.5;
}
.center-modal-hide {
    display: none;
}

/* Footer */
.footer {
    /* margin-top: 100px; */
    background-color: #f1f1f1;
}
.footer_ad {
    overflow: hidden;
    width: 70vw;
    margin: 0 auto;
    padding: 25px 0 25px 0;
}
.footer_logo {
    float: left;
    padding: 10px 45px 0 0;
}
.footer_logo img {
    width: 150px;
}
.footer_txt {
    float: left;
}
.footer_txt ul li {
    float: left;
    padding: 0 10px 12px 10px;
    font-size: 14px;
    background: url(../img/line02.png) no-repeat 0 5px;
}
.footer_txt ul li:first-child {
    padding-left: 0;
    background: none;
}
.footer_txt ul li a {
    color: #8e909c;
}
.footer_txt p {
    padding-bottom: 5px;
}
.address {
    clear: both;
    color: #b3b6bb;
    font-size: 13px;
    font-weight: normal;
}

.center-tab {
    font-size: 16px;
    border: 0px;
    width: 20%;
    padding: 15px;
    outline-style: none;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #dddcdc;
    color: #000;
    margin-top: 10px;
    font-weight: 900;
}

button.center-tab:hover {
    background-color: #166ec7;
    color: #fff;
}
button.center-tab.active {
    background-color: #166ec7;
    border: 1px solid #166ec7;
    color: #fff;
}

/* 예약 변경 Popup 디자인  */
.sportsman-wrap {
    width: 98%;
    height: 40px;
    border: 1px solid #166fc6;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    padding: 10px;
}
.sportsman-wrap:hover div.sportsman-cho {
    display: block;
    position: absolute;
    width: 80%;
    padding: 10px 10px 10px 10px;
}
.sportsman-wrap:hover div.sportsman {
    background-color: #f2f2f2;
    border-radius: 10px 10px 10px 10px;
    /* border-bottom: 1px solid #c3c3c3; */
}
.sportsman-wrap:hover div.sportsman-bt a.down {
    display: none;
}
.sportsman-wrap:hover div.sportsman-bt a.up {
    display: block;
}
.sportsman {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    font-size: 1.5em;
}
.sportsman div.sportsman-in p span {
    font-size: 0.8em;
    color: #666;
}
.sportsman-in {
    padding: 10px 10px;
}
.sportsman-bt {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #166fc6;
    text-align: center;
    margin: 0px 10px;
}
.sportsman-bt a {
    color: #fff;
    font-weight: 800;
    line-height: 30px;
    font-size: 0.8em;
}
.sportsman-bt a.down {
    display: block;
}
.sportsman-bt a.up {
    display: none;
}
.sportsman-cho {
    display: none;
    position: absolute;
    margin-top: 47px;
    width: 80%;
    max-height: 350px;
    height: auto;
    overflow: auto;
    padding: 10px 10px 10px 10px;
    border: 1px solid #166fc6;
    background-color: #f2f2f2;
    border-radius: 10px 10px 10px 10px;
    margin-left: 0px;
    font-size: 0.9em;
    z-index: 9999;
}
.sportsman-cho p input {
    margin-right: 10px;
}
.sportsman-datepicker-wrap {
    width: calc(100% - 13px);
    height: 40px;
    border: 1px solid #166fc6;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    padding: 0px 0px 0px 10px;
}
.react-datepicker__triangle {
    margin-left: -11px !important;
}

.mouse-pointer {
    cursor: pointer;
}

.reser-bt-blue {
    width: auto;
    background-color: #166fc6;
    border: 1px solid #166fc6;
    padding: 15px 10px;
    margin-top: 15px;
    text-align: center;
    border-radius: 8px;
    color: #fff !important;
    font-weight: 900;
}

.divTable {
    display: table;
    width: 100%;
}
.divTr {
    display: table-row;
}
.divTd {
    display: table-cell;
    align-items: top;
    overflow: hidden;
}
.divTd table {
    width: 100%;
    text-align: center;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
}
.divTd table th {
    background-color: #666;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    padding: 3px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
}
.divTd table td {
    padding: 3px;
    font-size: 12px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
}

.btn {
    font-weight: 800;
    border-radius: 3px;
    padding: 8px;
    margin: 0px 2px;
    font-size: 12px;
    border: 0px;
    background-color: #166ec7;
    color: #fff;
}
.btn-right {
    float: right;
}
.btn-success {
    background-color: #5cb85c;
    border-color: #4cae4c;
}
.btn-link {
    font-weight: 400;
    color: #337ab7;
    border-radius: 0;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.btn-info {
    background-color: #5bc0de;
    border-color: #46b8da;
}
.btn-warning {
    background-color: #f0ad4e;
    border-color: #eea236;
}
.btn-danger {
    background-color: #d9534f;
    border-color: #d43f3a;
}
.btn-letter {
    border: 1px solid #005e75;
    background-color: #008299;
}

/* Questionnaires Popup 20211216*/
.quest-popup-cont {
    padding-top: 5px;
    height: 600px;
}

.quest-anwser-tab {
    display: flex;
    min-height: 450px;
    height: 540px;
    overflow: auto;
    border: 1px solid #166ec7;
}

.quest-menu {
    width: 117px;
    min-height: 540px;
    float: left;
    border-right: 1px solid #166ec7;
}

.quest-menu ul li {
    background-color: #fff;
    color: #166ec7;
    padding: 15px 0px 15px 0px;
    border-bottom: 1px solid #166ec7;
    text-align: center;
}
.quest-menu ul li button {
    background-color: #fff;
    border: 0px;
    outline-style: none;
}
.quest-menu ul li.tabon {
    background-color: #166ec7;
    padding: 15px 0px;
    border-bottom: 1px solid #166ec7;
    color: #fff;
    font-weight: 900;
    font-size: 15px;
}

.quest-menu ul li.taboff {
    background-color: #fff;
    color: #166ec7;
    padding: 15px 0px;
    border-bottom: 1px solid #166ec7;
    font-weight: 700;
    font-size: 15px;
}

.quest-content {
    width: 720px;
    float: left;
    line-height: 18px;
    padding: 0px 10px 10px 10px;
    height: 530px;
    /* height: auto; */
    overflow-y: auto;
}

.quest-content p {
    font-size: 15px;
}

.quest-content::-webkit-scrollbar {
    width: 5px;
    background-color: #eee;
}
.quest-content::-webkit-scrollbar-thumb {
    width: 10px;
    background-color: #166ec7;
}
