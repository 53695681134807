@charset "UTF-8";
@import url(https://cdn.jsdelivr.net/font-nanum/1.0/nanumbarungothic/nanumbarungothic.css);
@import url(https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&display=swap);

div.reporting-body {
    margin: 0px;
    padding: 15px;
    font-size: 12px;
    font-family: 'Nanumbarungothic', sans-serif !important;
}

div.reporting-body .title {
    width: 100%;
    text-align: center;
    font-weight: 900;
    font-size: 25px;
    padding-bottom: 15px;
}
table.reporting-table {
    width: 100%;
    background-color: #fff;
}
table.reporting-table th,
table.reporting-table td {
    border-left: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
}

table.reporting-table th {
    background-color: #ddebf7;
    font-weight: 700;
    height: 30px;
    font-size: 1.1em;
}
table.reporting-table td {
    font-size: 1em;
    text-align: center;
    height: 30px;
    padding: 0px;
}

table.reporting-table .inner-table {
    width: 100%;
    border: 0px;
    margin: 0px;
}

table.reporting-table .inner-table th {
    font-size: 0.8em;
    height: 30px;
    border: 0px;
}
table.reporting-table .inner-table td {
    border: 0px;
}

table.eval-table {
    width: 100%;
    height: 60px;
    margin-top: 5px;
    background-color: #fff;
}
table.eval-table td {
    padding: 10px;
    vertical-align: top;
    font-size: 1.1em;
    height: 60px;
}

@media print {
    div,
    table {
        font-size: 10px;
    }

    table.reporting-table th,
    table.reporting-table td {
        border: 1px solid rgb(46, 46, 46);
        font-size: 8px;
        height: 20px;
    }
    table.reporting-table .inner-table th {
        font-size: 7px;
        height: 25px;
        border: 0px;
    }

    table.eval-table {
        width: 100%;
        height: 85px;
        border: 1px solid #000;
    }
}
