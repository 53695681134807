@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:100,300,400,500,700,900&subset=korean');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700');

/* header */
.header {
    height: 17vw;
    max-height: 360px;
}
.header_ad {
    width: 70vw;
    margin: 0 auto;
}
.header h1 {
    float: left;
    padding: 33px 0 20px 0;
}
.header h1 img {
    width: 126px;
}
.header .nav_ad {
    position: relative;
    float: right;
    width: calc(70vw - 200px);
}

.header-wrap-ad {
    /* position: relative; */
    display: flex;
    /* width: 75vw; */
    width: 1200px;
    margin: 0 auto;
}
.search {
    position: absolute;
    top: 55px;
    right: 10px;
    height: 22px;
    padding: 2px 10px;
    border: 2px solid #c7c7c7;
    border-radius: 100px;
}
.search input {
    float: left;
    width: 115px;
    height: 22px;
    padding: 3px;
    color: #959595;
    border: 0;
    outline: none;
    font-size: 14px;
}
.search button {
    color: #adadad !important;
}
.search .fa-search {
    padding-top: 1px;
    vertical-align: top;
}
.sub-nav {
    display: none;
    height: 200px;
    padding-top: 20px;
    margin-top: 0px;
    padding-bottom: 20px;
    margin-bottom: 0px;
}

.gnb {
    float: right;
    padding-top: 10px;
}
.gnb ul li {
    float: left;
    padding: 5px 12px;
    /* background: url(/iis/images/iis/line01.png) no-repeat 0 center; */
}
.gnb ul li a {
    color: #fff;
    font-size: 13px;
}
.gnb ul li:first-child {
    padding-left: 0;
    background: none;
}
.gnb ul li:last-child {
    padding-right: 0;
}

.pc_ver {
    position: absolute;
    top: 30px;
    left: 20px;
    width: 820px;
    text-align: center;
}
.pc_ver .nav {
    text-align: center;
    vertical-align: top;
}
.pc_ver .nav li {
    text-align: left;
}
.pc_ver .nav li a {
    display: block;
    padding: 5px 0;
}
.pc_ver .nav > li {
    display: inline-block;
    vertical-align: top;
}
.pc_ver .nav > li span {
    display: block;
    position: relative;
    height: 80px;
    padding: 0 25px;
    font-size: 19px;
    font-weight: 400;
    text-align: center;
    letter-spacing: -0.02em;
    line-height: 4;
    cursor: pointer;
}
.pc_ver .nav > li span:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 4px;
    background-color: #1767da;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.pc_ver .nav > li span a {
    color: #fff;
}
.pc_ver .nav > li .sub_nav {
    display: none;
    position: absolute;
    top: 76px;
    border-top: 4px solid #1767da;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    padding: 15px 10px;
    background-color: #fff;
}
.pc_ver .nav > li .sub_nav1 {
    left: -20px;
}
.pc_ver .nav > li .sub_nav2 {
    left: 105px;
}
.pc_ver .nav > li .sub_nav3 {
    left: 240px;
}
.pc_ver .nav > li .sub_nav:after {
    content: '';
    display: block;
    position: absolute;
    top: 15%;
    left: 0;
    width: 1px;
    height: 70%;
}
.pc_ver .nav > li .sub_nav li a {
    display: block;
    width: 130px;
    padding: 0 20px 7px 15px;
    color: #595959;
    font-size: 15px;
    line-height: 1.5;
}
.pc_ver .nav > li .sub_nav li a:hover {
    color: #1767da;
}
.pc_ver .nav > li:first-child .sub-nav:after {
    display: none;
}
.pc_ver .nav > li:hover span:after {
    width: 100%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.mobile-ver {
    display: none;
}

.sitemap {
    float: right;
}
.sitemap_btn {
    position: absolute;
    right: 0;
    top: 70px;
    width: 22px;
    height: auto;
    line-height: 0;
    display: block;
    text-indent: -9999em;
    overflow: hidden;
    z-index: 100;
}
.sitemap_btn:hover {
    background-color: #fff;
}
.sitemap_btn span {
    display: block;
    float: right;
    width: 70%;
    margin: 3px 0;
    height: 2px;
    background: #555;
}
.sitemap_btn span:first-child {
    width: 100%;
}
.sitemap_btn span:last-child {
    width: 100%;
}
.sitemap_btn:hover span {
    width: 100% !important;
    background: #004a96;
}
.sitemap_btn,
.sitemap_btn span {
    transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}

/* 서브 비주얼 */
.sub_visual {
    height: 18vw;
    min-height: 300px;
}
/* .sub_visual1 {
    background: url(/iis/images/iis/sub_visual01.jpg) no-repeat center 0;
    background-size: cover;
}
.sub_visual2 {
    background: url(/iis/images/iis/sub_visual02.jpg) no-repeat center 0;
    background-size: cover;
}
.sub_visual3 {
    background: url(/iis/images/iis/sub_visual03.jpg) no-repeat center 0;
    background-size: cover;
}
.sub_visual4 {
    background: url(/iis/images/iis/sub_visual04.jpg) no-repeat center 0;
    background-size: cover;
}
.sub_visual5 {
    background: url(/iis/images/iis/sub_visual05.jpg) no-repeat center 0;
    background-size: cover;
}
.sub_visual6 {
    background: url(/iis/images/iis/sub_visual06.jpg) no-repeat center 0;
    background-size: cover;
} */

.subTop {
    overflow: hidden;
    width: 100%;
    padding-top: 98px;
    border-top: 1px solid #ffffff60;
    text-align: center;
    color: #fff;
}
.subTop h2 {
    padding-top: 2.8vw;
    font-size: 48px;
}
.subTop .line {
    display: block;
    width: 40px;
    height: 4px;
    margin: 18px auto;
    background-color: #fff;
}
.subTop p {
    font-size: 15px;
    font-weight: normal;
}

/* 서브 레이아웃 */
.sub_contents {
    overflow: hidden;
    width: 70vw;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 100px;
}
.snb {
    float: left;
    width: 240px;
    padding-right: 60px;
}
.snb h2 {
    height: 95px;
    margin-bottom: 10px;
    padding-top: 55px;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -1px;
    text-align: center;
    /* background: url(/iis/images/iis/snbTleBg.png) no-repeat 0 0; */
    color: #fff;
}
.snb ul {
    border-bottom: 1px solid #d8d8d8;
}
.snb ul {
    border-bottom: 1px solid #d8d8d8;
}
.snb ul li {
    width: 100%;
}
.snb ul li a {
    display: block;
    padding: 14px 15px;
    border: 1px solid #d8d8d8;
    border-bottom: 0;
    /* background: url(/iis/images/iis/arrow_black.png) no-repeat 93% 50%; */
}
.snb ul li.menu_on a {
    border: 1px solid #0c2e88;
    /* background: url(/iis/images/iis/arrow_white.png) no-repeat 93% 50% #0c2e88; */
    color: #fff;
}

.contents_ad {
    float: left;
    width: calc(70vw - 300px);
}
.page_tle {
    overflow: hidden;
    width: 100%;
    margin-bottom: 50px;
    padding-top: 10px;
    border-bottom: 1px solid #ccc;
}
.page_tle h3 {
    display: inline-block; /*float:left;*/
    padding-bottom: 5px;
    border-bottom: 4px solid #2a5ea9;
    font-size: 30px;
    font-weight: 500;
}
.page_tle .page_nav {
    float: right;
    padding-top: 20px;
}
.page_tle .page_nav ul li {
    float: left;
    padding: 0 8px 0 11px;
    /* background: url(/iis/images/iis/arrow_black2.png) no-repeat 0 5px; */
    font-size: 14px;
    letter-spacing: -1px;
}
.page_tle .page_nav ul li:first-child {
    padding: 0 8px 0px 21px;
    /* background: url(/iis/images/iis/bu_home.png) no-repeat 0 50%; */
}
.page_tle .page_nav ul li:last-child {
    padding-right: 0;
}
.page_tle .page_nav ul li a {
    color: #818181;
}

.page_sub_tle {
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px;
    padding-top: 20px;
    border-bottom: 1px solid #ccc;
}
.page_sub_tle h3 {
    display: inline-block; /*float:left;*/
    padding-bottom: 5px;
    border-bottom: 4px solid #2a5ea9;
    font-size: 20px;
    font-weight: 500;
}

/* 서브 컨텐츠 */
.ready {
    padding: 80px;
    text-align: center;
}

.terms {
    padding: 40px;
    border: 1px solid #e5e5e5;
}
.terms dl dt {
    font-size: 18px;
    color: #404040;
    font-weight: 700;
    margin-bottom: 0.5vw;
}
.terms dl dd {
    color: #595959;
    font-weight: 300;
    margin-bottom: 1.4vw;
}

.list_tle {
    padding-bottom: 2vw;
    text-align: center;
    letter-spacing: -1px;
}
.list_tle h3 {
    display: inline-block;
    font-size: 34px;
    vertical-align: bottom;
}
.tabType1 {
    overflow: hidden;
    padding: 0.5vw 1.4vw 0.8vw 1.4vw;
    text-align: center;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
}
.tabType1 ul li {
    display: inline-block;
    margin: 1px 0;
    padding: 0.4vw 1.2vw;
    word-break: keep-all;
    cursor: pointer;
    font-size: 17px;
    /* background: url(/iis/images/iis/dot1.jpg) no-repeat 100% center; */
}
.tabType1 ul li:last-child {
    background: none;
}
.tabType1 .tab_on {
    border-radius: 100px;
    background: #2d4fc1;
    color: #fff;
}
.btn_more {
    display: inline-block;
    padding: 10px;
}
.btn_more a {
    padding: 2px 14px;
    border: 2px solid #016ecd;
    color: #016ecd;
    border-radius: 100px;
    font-weight: 600;
}

.introduce_txt img {
    width: 100%;
    padding-bottom: 2.5vw;
}
.introduce_txt01 {
    font-size: 25px;
    padding-bottom: 1.5vw;
}
.introduce_txt01 span {
    color: #145ec7;
    font-weight: 500;
}
.introduce_txt02 {
    padding-bottom: 1.5vw;
    color: #464646;
    font-weight: 300;
}
.introduce_txt03 {
    padding-top: 2vw;
    text-align: right;
}

.vision {
    padding-top: 1.5vw;
}
.vision_txt01 {
    width: 390px;
    margin: 0 auto;
    padding: 30px 0;
    background-color: #017fe0;
    color: #fff;
    border-radius: 0 40px 0 40px;
    text-align: center;
}
.vision_txt01 p {
    font-size: 25px;
    font-weight: 600;
}
.vision .goal {
    width: 310px;
    padding: 60px 0;
    margin: 50px auto 0 auto;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    /* background: url(/iis/images/iis/vision_arrow.png) no-repeat 0 0; */
}
.vision .goal_list {
    overflow: hidden;
    padding: 3vw 2vw 2vw 2vw;
}
.vision .goal_list dl {
    float: left;
    width: 33.333333333%;
    text-align: center;
}
.vision .goal_list dl dt {
    margin-bottom: 15px;
    padding: 2vw 0;
    background-color: #05c6c7;
    color: #fff;
    border-radius: 100px;
    font-size: 18px;
    font-weight: 500;
    line-height: 110%;
}
.vision .goal_list dl dt span {
    font-size: 15px;
    font-weight: 300;
}
.vision .goal_list dl dd {
    padding: 1vw 10px 0 2vw;
    color: #464646;
    text-align: left;
    font-size: 15px;
}
.vision .goal_list dl.list_blue {
    margin-left: -10px;
}
.vision .goal_list dl.list_gray {
    margin-left: -10px;
}
.vision .goal_list dl.list_blue dt {
    background-color: #375dde;
}
.vision .goal_list dl.list_gray dt {
    background-color: #91a9ce;
}

.organizationImg {
    padding: 2vw 2vw 4vw 2vw;
    text-align: center;
}
.organizationImg img {
    width: 100%;
    max-width: 850px;
}
.pic_btn a {
    display: none;
}

/* 테이블 */
.auto_wrap {
    position: relative;
}
.auto_info {
    display: none;
    position: relative;
    text-align: center;
}
.auto_info .txt {
    display: inline-block;
    position: relative;
    padding: 0 10px 10px 10px;
    line-height: 1.3;
    letter-spacing: 0;
    font-size: 14px;
    color: #aeb1b9;
}
.auto_info_ad {
    position: relative;
    overflow-x: auto;
    width: 100%;
    margin-bottom: 30px;
    padding-top: 0px;
}
.auto_info_ad > table {
    min-width: 720px;
}
.table_type01 {
    clear: both;
    position: relative;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0px;
    table-layout: fixed;
    text-align: center;
    border-top: 2px solid #2655ae;
    font-size: 1em;
    border-bottom: 1px solid #e5e5e5;
    font-size: 0.941em;
}
.table_type01 thead th {
    position: relative;
    padding: 18px 10px;
    background: #f2f3f6;
    color: #0d285b;
    font-weight: 400;
}
.table_type01 thead th:after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 50%;
    background: #d8dbe5;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.table_type01 thead th:last-child:after {
    display: none;
}
.table_type01 tbody th {
    font-weight: 400;
    color: #353a44;
}
.table_type01 tfoot th {
    font-weight: 400;
    color: #353a44;
}
.table_type01 tbody td {
    font-weight: 400;
    color: #555555;
}
.table_type01 tbody th,
.table_type01 tbody td,
.table_type01 tfoot th,
.table_type01 tfoot td {
    position: relative;
    border-bottom: 1px solid #e5e5e5;
    padding: 16px 16px 16px;
    overflow: hidden;
    border-right: 1px dashed #e5e5e5;
    background: #fff;
}
.table_type01 tbody th:last-child,
.table_type01 tbody td:last-child,
.table_type01 tfoot th:last-child,
.table_type01 tfoot td:last-child {
    border-right: 0;
}
.table_type01 tbody td:last-child,
.table_type01 tfoot td:last-child {
    border-right: 0;
}
.table_type01.hasColRowThead thead th:after {
    display: none;
}
.table_type01.hasColRowThead thead th {
    position: static;
    border-right: 1px solid #e5e5e5;
}
.table_type01.hasColRowThead thead th:last-child {
    border-right: 0;
}
.table_type01.hasColRowThead thead th[colspan] {
    border-bottom: 1px solid #e5e5e5;
}
@media all and (max-width: 999px) {
    .auto_info {
        display: block;
    }
    .table_type01 thead th:after {
        top: 50%;
        transform: translateY(-50%);
    }
    .table_type01 thead th:after {
        height: 50%;
    }
    .table_type01 thead th {
        padding: 10px 5px;
    }
    .table_type01 tbody th,
    .table_type01 tbody td,
    .table_type01 tfoot th,
    .table_type01 tfoot td {
        padding: 10px 5px;
    }
}

.tle_h4 {
    padding-bottom: 15px;
    font-weight: normal;
    font-size: 18px;
}
.bu_square {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 7px;
    border: 4px solid #4058aa;
}
.tle_h4_2 {
    font-size: 22px;
    font-weight: 500;
}
.txt_type1 {
    padding-top: 10px;
    color: #525252;
    font-weight: 300;
}
.txt_type2 {
    margin-bottom: 90px;
}
.txt_type3 {
    margin-bottom: 50px;
}
.logoType {
    margin: 50px 0;
    padding: 7vw 10vw;
    border: 1px solid #dbdbdb;
    text-align: center;
}
.logoType img {
    width: 100%;
    max-width: 250px;
}
.btn_download {
    border: 1px solid c8c8c8;
}
.logo_color {
    padding-top: 2vw;
}
.logo_color li {
    float: left;
    width: 18%;
    padding-right: 2%;
    color: #525252;
    font-weight: 300;
}
.logo_color li i {
    display: block;
    widht: 100%;
    height: 45px;
    margin-bottom: 10px;
}
.logo_color li i.logo_color1 {
    background-color: #005baa;
}
.logo_color li i.logo_color2 {
    background-color: #00ac6c;
}
.logo_color li i.logo_color3 {
    background-color: #faa61a;
}
.logo_color li i.logo_color4 {
    background-color: #f15a38;
}

.historyImg {
    padding-bottom: 60px;
}
.historyImg img {
    width: 50%;
}
.historyImg2 {
    width: calc(50% - 20px) !important;
    padding: 50px 0 0 20px;
}
.history_year {
    width: 130px;
    font-size: 34px;
    font-weight: 600;
    color: #4058aa !important;
    line-height: 110%;
}
.history_month {
    width: 60px;
}
.table_type02 {
    word-break: break-all;
}
.table_type02 td {
    padding: 7px;
    color: #525252;
    vertical-align: top;
}
.table_type02 .line {
    padding-bottom: 30px;
    border-bottom: 1px dotted #cad2de;
}
.table_type02 .line2 {
    padding-top: 30px;
}

/* 찾아오시는 길 */
.map {
    height: 460px;
    background-color: #f9f9f9;
}
.traffic-wr {
    margin-top: 18px;
    border-top: 2px solid #0d285b;
}
.traffic-wr .in-sec {
    position: relative;
    padding-left: 260px;
}
.traffic-wr .traffic-sec {
    position: absolute;
    left: 0;
    top: 0;
    width: 250px;
    height: 100%;
    text-align: center;
    border-bottom: 1px solid #ddd;
    display: table;
}
.traffic-wr .traffic-sec h4 {
    display: table-cell;
    color: #000;
    font-size: 1.059em;
    font-weight: 400;
    line-height: 1;
    vertical-align: middle;
}
.traffic-wr .traffic-sec h4:before {
    content: '';
    display: block;
    width: 60px;
    height: 60px;
    margin: -20px auto 10px auto;
    /* background: url(/iis/images/iis/directions.png) no-repeat 0 0; */
    background-size: 300px 60px;
}
.traffic-wr .traffic-sec .bus:before {
    background-position: 0 0;
}
.traffic-wr .traffic-sec .train:before {
    background-position: -60px 0;
}
.traffic-wr .traffic-sec .plane:before {
    background-position: -120px 0;
}
.traffic-wr .traffic-sec .highway:before {
    background-position: -180px 0;
}
.traffic-wr .traffic-sec .car:before {
    background-position: -240px 0;
}
.traffic-wr .course-list {
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
    min-height: 100px;
}
.traffic-wr .course-line {
    position: relative;
    margin: 10px 0;
    padding-left: 130px;
    min-height: 40px;
}
.traffic-wr .course-line h5 {
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px;
    width: 85px;
    color: #000;
    font-size: 0.941em;
    text-align: center;
    line-height: 1;
    border: 1px solid #a1aec8;
    font-weight: 500;
}
.traffic-wr .course-list.spc .course-line {
    padding-left: 190px;
}
.traffic-wr .course-list.spc .course-line h5 {
    width: 150px;
}
.traffic-wr .course-line ul {
    overflow: hidden;
    padding-top: 5px;
}
.traffic-wr .course-line li {
    position: relative;
    float: left;
    padding: 0 30px 0 0;
    font-size: 0.941em;
    color: #555;
}
.traffic-wr .course-line li:after {
    content: '';
    position: absolute;
    right: 0;
    top: 5px;
    width: 18px;
    height: 18px;
    /* background: url(/iis/images/iis/arrow_black2.png) no-repeat 0 0; */
}
.traffic-wr .course-line li:first-child {
    padding-left: 30px;
}
.traffic-wr .course-line li:first-child:before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 16px;
    height: 23px;
    /* background: url(/iis/images/iis/icon_map.png) no-repeat 0 0; */
    background-size: 14px;
}

/* 게시판 */
.sub_contents .board {
    padding-top: 1.5vw;
}
.sub_contents .board .search_box {
    overflow: hidden;
    float: right;
    padding-bottom: 20px;
}
.sub_contents .board .search_box ul li {
    float: left;
}
.sub_contents .board .search_box select {
    float: left;
    width: 120px;
    height: 40px;
    margin-right: 4px;
    padding: 7px;
    appearance: revert;
    border: 1px solid #dddddd;
    font-size: 15px;
    font-weight: 300;
    color: #2f2f2f;
}
.sub_contents .board .search_box .s_input {
    float: left;
    width: 15vw;
    height: 40px;
    margin-right: 4px;
    border: 1px solid #dddddd;
    font-weight: 300;
    color: #2f2f2f;
}
.sub_contents .board .search_box .s_btn {
    cursor: pointer;
    width: 56px;
    height: 40px;
    border: 0;
    background-color: #41464e;
    color: #fff;
    font-size: 15px;
    font-weight: 300;
}

.sub_contents .board h1 {
    display: none;
}
.sub_contents .board_list {
    width: 100%;
    border-top: 2px solid #505c78;
}
.sub_contents .board_list th {
    padding: 0.9vw;
    background-color: #f9fafb;
    border-bottom: 1px solid #d2d7dc;
    font-weight: 300;
}
.sub_contents .board_list td {
    padding: 0.9vw;
    border-bottom: 1px solid #eaecf1;
    text-align: center;
    color: #383838;
    font-weight: 300;
}
.sub_contents .board_list .board_subj {
    cursor: pointer;
}

.sub_contents .board_list .left {
    text-align: left;
}
.sub_contents .board .pagination {
    padding-top: 40px;
}
.sub_contents .board .pagination a {
    display: block;
    width: 38px;
    padding: 6px 0;
    text-align: center;
    color: #484848;
    font-weight: 300;
}
.sub_contents .board .pagination .current {
    border-color: #365ec3;
    color: #365ec3;
}
.sub_contents .pagination li {
    border-width: 1px !important;
}

.sub_contents .board_view {
    text-align: left;
}
.sub_contents .board_view th {
    border-bottom: 1px solid #eaecf1;
}
.sub_contents .board_view td {
    text-align: left;
}
.sub_contents .board_view .board_tle {
    padding: 1.1vw;
    color: #000;
    font-weight: 400;
    font-size: 18px;
}
.sub_contents .board_view .board_txt {
    padding: 20px 10px 40px 10px;
    text-align: left;
    vertical-align: top;
}
.sub_contents .board_view .board_txt div {
    min-height: 200px;
}
.sub_contents .board_view .file li {
    padding: 0 0 5px 22px;
    /* background: url(/iis/images/iis/bu_file.png) no-repeat 0 6px; */
}
.sub_contents .btn_list_gray {
    margin-top: 50px;
    text-align: center;
}
.sub_contents .btn_list_gray a {
    display: inline-block;
    width: 100px;
    padding: 11px 0 12px 0;
    text-align: center;
    color: #fff;
    background-color: #41464e;
}

@media all and (max-width: 1280px) {
    .cpmap-wr .cpmap .map-info.tel {
        margin-left: 30px;
    }
    .cpmap-wr .cpmap .map-info.tel dt {
        margin-left: 40px;
    }
    .cpmap-wr .cpmap .map-info.tel dt:before {
        left: -25px;
        top: 10px;
    }
}
@media all and (max-width: 1200px) {
    .traffic-wr .in-sec {
        padding-left: 150px;
    }
    .traffic-wr .traffic-sec {
        width: 150px;
    }
    .cpmap-wr .cpmap {
        box-sizing: border-box;
        padding: 25px 0 0 40px;
    }
    .cpmap-wr .cpmap .map-info {
        float: none;
        padding-top: 0;
    }
    .cpmap-wr .cpmap .map-info.tel {
        display: block;
        margin-left: 0;
    }
    .cpmap-wr .cpmap .map-info dt.address:before {
        margin: 0 10px -4px 0;
    }
}
@media all and (max-width: 999px) {
    .cpmap-wr .cpmap .map-info.tel dt:before {
        top: 8px;
    }
}
@media all and (max-width: 899px) {
    .traffic-wr .in-sec {
        padding: 10px 0 0;
        margin: 10px 0 0;
        border-top: 1px dashed #ddd;
    }
    .traffic-wr .in-sec:first-child {
        margin: 0;
        border-top: 0;
    }
    .traffic-wr .traffic-sec {
        display: block;
        position: relative;
        width: auto;
        height: auto;
        left: auto;
        top: auto;
        text-align: left;
        border-bottom: 0;
    }
    .traffic-wr .traffic-sec h4 {
        display: block;
        box-sizing: border-box;
        position: relative;
        padding: 17px 0 17px 55px;
    }
    .traffic-wr .traffic-sec h4:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 40px;
        margin: 0;
        background-position: 0 0;
        background-size: 200px auto;
    }
    .traffic-wr .course-list {
        padding: 0;
        border-bottom: 0;
        min-height: 0;
    }
    .traffic-wr .traffic-sec .bus:before {
        background-position: 0 0;
    }
    .traffic-wr .traffic-sec .train:before {
        background-position: -40px 0;
    }
    .traffic-wr .traffic-sec .plane:before {
        background-position: -80px 0;
    }
    .traffic-wr .traffic-sec .highway:before {
        background-position: -120px 0;
    }
    .traffic-wr .traffic-sec .car:before {
        background-position: -160px 0;
    }
    .cpmap-wr .root_daum_roughmap {
        height: 400px !important;
    }
    .cpmap-wr .cpmap {
        padding: 25px 0 0 20px;
    }
}
@media all and (max-width: 768px) {
    .traffic-wr .course-line li:first-child {
        padding-left: 23px;
        padding-right: 23px;
    }
    .traffic-wr .course-line li:first-child:before {
        top: 1px;
    }
    .traffic-wr .course-line li:after {
        top: 2px;
    }
}
@media all and (max-width: 699px) {
    .cpmap-wr .cpmap {
        padding: 20px 120px 20px 20px;
        height: auto;
    }
    .cpmap-wr .cpmap .expand-btn {
        width: 100px;
    }
    .cpmap-wr .cpmap .expand-btn .stit {
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        padding-top: 0;
    }
    .cpmap-wr .cpmap .map-info.addr dd {
        display: block;
        margin: 0 0 0 32px;
    }
    .cpmap-wr .cpmap .map-info dt {
        margin-right: 11px;
    }
}
@media all and (max-width: 599px) {
    .traffic-wr .course-line {
        padding-left: 0;
        min-height: 0;
    }
    .traffic-wr .course-line h5 {
        position: relative;
        left: auto;
        top: auto;
    }
    .traffic-wr .course-list.spc .course-line {
        padding-left: 0;
    }
    .traffic-wr .course-line {
        margin: 30px 0;
    }
}
@media all and (max-width: 499px) {
    .cpmap-wr .cpmap {
        padding: 20px;
    }
    .cpmap-wr .cpmap .expand-btn {
        position: relative;
        right: auto;
        top: auto;
        width: auto;
        height: auto;
        padding: 10px 10px;
        margin: 10px 0 0;
    }
    .cpmap-wr .cpmap .expand-btn .stit:before {
        display: inline-block;
        margin: 0 10px 5px 0;
        vertical-align: middle;
    }
    .cpmap-wr .cpmap .expand-btn .stit {
        position: relative;
        left: auto;
        top: auto;
        transform: none;
        display: inline-block;
        vertical-align: middle;
    }
    .traffic-wr .course-line {
        margin: 30px 0;
    }
}
@media all and (max-width: 399px) {
    .cpmap-wr .cpmap {
        padding: 15px;
    }
    .cpmap-wr .cpmap .map-info.tel dt {
        margin-left: 20px;
    }
    .cpmap-wr .cpmap .map-info.tel dt:before {
        left: -15px;
    }
}

/* 푸터 */
.footer {
    /* margin-top:100px; */
    background-color: #f1f1f1;
}
.footer_ad {
    overflow: hidden;
    width: 70vw;
    margin: 0 auto;
    padding: 40px 0 35px 0;
}
.footer_logo {
    float: left;
    padding: 10px 45px 0 0;
}
.footer_logo img {
    width: 126px;
}
.footer_txt {
    float: left;
}
.footer_txt ul li {
    float: left;
    padding: 0 10px 12px 10px;
    font-size: 14px;
    /* background: url(/iis/images/iis/line02.png) no-repeat 0 5px; */
}
.footer_txt ul li:first-child {
    padding-left: 0;
    background: none;
}
.footer_txt ul li a {
    color: #8e909c;
}
.footer_txt p {
    padding-bottom: 5px;
}
.address {
    clear: both;
    color: #b3b6bb;
    font-size: 13px;
    font-weight: normal;
}

/* PC  (해상도 1241px ~ 1600px) */
@media all and (min-width: 1241px) and (max-width: 1600px) {
    .header-wrap-ad {
        width: 85vw !important;
    }

    .sub_contents {
        width: 85vw;
        padding-top: 20px;
    }
    .contents_ad {
        width: calc(85vw - 300px);
    }

    .footer_ad {
        width: 85vw;
        padding: 20px 0 20px 0;
    }
    .footer_logo {
        float: none;
        padding: 10px 0 20px 0;
    }
    .footer_logo img {
        width: 100px;
    }
    .footer_txt ul li {
        padding: 0 8px 12px 8px;
    }
}

/* PC  (해상도 1078px ~ 1240px) */
@media all and (min-width: 1078px) and (max-width: 1240px) {
    .header-wrap-ad {
        width: 85vw !important;
    }
    header nav.pc-ver .nav > li span {
        font-size: 16px;
    }
    header nav.pc-ver .nav li .sub-nav li a {
        font-size: 12px;
    }

    .main_contents {
        width: 820px;
        margin: 0 auto;
    }
    .main_contents .em_link {
        display: none;
    }

    .footer_ad {
        width: 85vw;
        padding: 20px 0 20px 0;
    }
    .footer_logo {
        float: none;
        padding: 10px 0 20px 0;
    }
    .footer_logo img {
        width: 100px;
    }
    .footer_txt ul li {
        padding: 0 8px 12px 8px;
    }
}

/* PC  (해상도 1024px ~ 1080px) */
@media all and (min-width: 1024px) and (max-width: 1080px) {
    .header-wrap-ad {
        width: 90vw !important;
    }

    .main_contents {
        width: 820px;
        margin: 0 auto;
    }
    .main_contents .em_link {
        display: none;
    }

    .sub_contents {
        width: 85vw;
        padding-top: 20px;
    }
    .contents_ad {
        float: none;
        width: auto;
    }
    .snb {
        display: none;
    }
    .page_tle {
        margin-bottom: 30px;
    }
    .page_tle .page_nav {
        display: none;
    }
    .page_tle h3 {
        font-size: 22px;
    }

    .footer_ad {
        width: 85vw;
        padding: 20px 0 20px 0;
    }
    .footer_logo {
        float: none;
        padding: 10px 0 20px 0;
    }
    .footer_logo img {
        width: 100px;
    }
    .footer_txt ul li {
        padding: 0 8px 12px 8px;
    }
}

/* PC , 테블릿 가로 (해상도 768px ~ 1023px) */
@media all and (min-width: 768px) and (max-width: 1023px) {
    .header-wrap-ad {
        width: 85vw !important;
    }

    .main_contents {
        width: 820px;
        margin: 0 auto;
    }
    .main_contents .em_link {
        display: none;
    }

    .sub_contents {
        width: 85vw;
        padding-top: 20px;
    }
    .contents_ad {
        float: none;
        width: auto;
    }
    .snb {
        display: none;
    }
    .page_tle {
        margin-bottom: 30px;
    }
    .page_tle .page_nav {
        display: none;
    }
    .page_tle h3 {
        font-size: 22px;
    }

    .footer_ad {
        width: 85vw;
        padding: 20px 0 20px 0;
    }
    .footer_logo {
        float: none;
        padding: 10px 0 20px 0;
    }
    .footer_logo img {
        width: 90px;
        height: 40px;
    }
    .footer_txt ul li {
        padding: 0 8px 12px 8px;
    }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px) */
@media all and (min-width: 480px) and (max-width: 767px) {
    .sub_contents {
        width: 85vw;
        padding-top: 20px;
    }
    .contents_ad {
        float: none;
        width: auto;
    }
    .snb {
        display: none;
    }
    .page_tle {
        margin-bottom: 30px;
    }
    .page_tle .page_nav {
        display: none;
    }
    .page_tle h3 {
        font-size: 22px;
    }

    .ready {
        padding: 0;
        text-align: center;
    }
    .ready img {
        width: 100%;
    }

    .footer_ad {
        width: 85vw;
        padding: 20px 0 20px 0;
    }
    .footer_logo {
        float: none;
        padding: 10px 0 20px 0;
    }
    .footer_logo img {
        width: 90px;
        height: 40px;
    }
    .footer_txt ul li {
        padding: 0 8px 12px 8px;
    }
}

/* 모바일 가로, 테블릿 세로 (해상도 ~ 479px) */
@media all and (max-width: 479px) {
    .sub_contents {
        width: 85vw;
        padding-top: 10px;
    }
    .contents_ad {
        float: none;
        width: auto;
    }
    .snb {
        display: none;
    }
    .page_tle {
        margin-bottom: 30px;
    }
    .page_tle .page_nav {
        display: none;
    }

    .subTop h2 {
        padding-top: 6.8vw;
        font-size: 36px;
    }
    .page_tle h3 {
        font-size: 22px;
    }
    .list_tle h3 {
        font-size: 26px;
        vertical-align: top;
    }
    .tabType1 {
        padding: 2.8vw 1.4vw;
    }
    .tabType1 ul li {
        padding: 0.4vw 3vw 0.4vw 1.4vw;
        font-size: 15px;
    }
    .tabType1 .tab_on {
        padding: 0.4vw 3vw;
    }
    .btn_more a {
        font-size: 12px;
    }

    .ready {
        padding: 0;
        text-align: center;
    }
    .ready img {
        width: 100%;
    }

    .introduce_txt img {
        width: 100%;
        padding-bottom: 5vw;
    }
    .introduce_txt01 {
        font-size: 19px;
        padding-bottom: 3vw;
    }
    .introduce_txt02 {
        font-size: 14px;
        padding-bottom: 3vw;
    }
    .introduce_txt03 {
        font-size: 14px;
    }
    .vision_txt01 {
        width: 280px;
        padding: 20px 0;
        font-size: 14px;
    }
    .vision_txt01 p {
        font-size: 21px;
    }
    .vision .goal {
        margin-top: 30px;
    }
    .vision .goal_list {
        padding: 4vw 0;
    }
    .vision .goal_list dl {
        width: 100%;
        padding-bottom: 10px;
    }
    .vision .goal_list dl.list_blue {
        margin-left: 0;
    }
    .vision .goal_list dl.list_gray {
        margin-left: 0;
    }
    .vision .goal_list dl dt {
        padding: 3vw 0;
        font-size: 16px;
    }
    .vision .goal_list dl dd {
        font-size: 14px;
    }

    .pic_btn {
        padding-bottom: 20px;
    }
    .pic_btn a {
        display: block;
        padding: 10px;
        margin: 10px 0;
        text-align: center;
        border: 1px solid #ddd;
    }

    .tle_h4_2 {
        font-size: 20px;
    }
    .txt_type1 {
        font-size: 14px;
    }
    .txt_type2 {
        margin-bottom: 30px;
    }
    .logoType {
        padding: 7vw 22vw;
    }
    .logo_color li {
        width: 22%;
        font-size: 13px;
        text-align: center;
    }
    .logo_color {
        padding-top: 9vw;
    }

    .map {
        height: 240px;
    }
    .table_type02 {
        font-size: 14px;
    }
    .history_year {
        width: 60px;
        font-size: 20px;
    }
    .history_month {
        width: 30px;
    }

    .sub_contents .board_list {
        border: 0;
    }
    .sub_contents .board_list thead {
        display: none;
    }
    .sub_contents .board_list tr {
        display: block;
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #eaecf1;
        font-size: 11px;
    }
    .sub_contents .board_list td {
        border: 0;
    }
    .sub_contents .board_list td:first-child {
        display: block;
        font-size: 15px;
        font-weight: 700;
    }
    .sub_contents .board_list td:last-child {
        display: none;
    }
    .sub_contents .board_list .left {
        display: block;
        font-size: 16px;
    }
    .sub_contents .board .search_box select {
        width: 85px;
        font-size: 13px;
    }
    .sub_contents .board .search_box .s_input {
        width: 100px;
    }
    .sub_contents .board_list .board_subj {
        display: inline-block;
        width: 100%;
    }

    .sub_contents .board_view td:first-child {
        display: inline-block;
    }
    .sub_contents .board_view td:last-child {
        display: inline-block;
    }
    .sub_contents .board_view th {
        padding-right: 10px;
        background-color: #fff;
        border-bottom: 0;
        border-right: 1px solid #d2d7dc;
    }
    .sub_contents .board_view .board_txt div {
        min-height: 50px;
    }
    .sub_contents .board_view th {
        display: inline-block;
    }
    .sub_contents .board_view tr:last-child {
        margin-bottom: 0;
    }
    .sub_contents .board_view td {
        display: inline-block;
    }
    .sub_contents .board_view .board_txt {
        padding: 10px 10px 40px 0;
    }

    .footer {
        margin-top: 50px;
    }
    .footer_ad {
        width: 85vw;
        padding: 20px 0 20px 0;
    }
    .footer_logo {
        float: none;
        padding: 10px 0 20px 0;
    }
    .footer_logo img {
        width: 90px;
        height: 40px;
    }
    .footer_txt ul li {
        padding: 0 8px 12px 8px;
    }
}
